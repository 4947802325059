export const API_LIST = Object.freeze({
  AUTH: {
    REGISTER: '/auth/register',
    SIGN_IN: '/auth/login',
    SIGN_OUT: '/auth/logout',
  },

  asset: {
    root: '/asset',
    icecat: '/asset/icecat',
    createManual: '/asset/manual',
    parseCsv: '/asset/parse-csv',
    parseExcel: '/asset/parse-excel',
    filters: '/asset/filters',
    availableStorages: '/asset/move-to/available-storages',
    availableLocations: '/asset/assign/available-locations',
    statusesCount: '/asset/count-of-assets-per-status',
    brands: 'asset/brands-list',
    actions: {
      assign: '/asset/action/assign',
      changeLocation: '/asset/action/change-location',
      moveToStorage: '/asset/action/move-to-storage',
      archive: '/asset/action/archive',
    },
    assetStock: '/asset/location-stock',
    emptySerialNumberCount: '/asset/list-count-with-empty-serial-number',
  },

  company: {
    root: '/company',
    details: (companyId: string) => `${API_LIST.company.root}/${companyId}`,
    staff: '/company-staff',
    staffRole: '/company-staff-role',
    staffTeam: '/company-staff-team',
    staffFilters: '/company-staff/filters',
    staffParseCsv: '/company-staff/parse-csv',
    syncHrSystem: '/company-staff/sync-hr-system',
    disableIntegration: '/company-staff/disable-integration',
    office: '/company-office',
    warehouse: '/warehouse',
    officeFilters: '/company-office/filters',
    officeLocations: '/company-office/locations',
    spaceLocation: '/company-office-space',
    activities: '/company/activities',
    scales: '/company/scales',
    credits: {
      logs: (id: string) => `/company-credits/${id}/credits-logs`,
      addCredits: (id: string) => `/company-credits/${id}/give-credits`,
      removeCredits: (id: string) => `/company-credits/${id}/remove-credits`,
    },

    // ONLY FOR SUPER ADMIN!!!
    superAdminCompaniesList: '/company/get-list',

    admin: {
      root: '/company-admin',
    },

    auth: {
      login: '/company-admin-auth/login',
      logout: '/company-admin-auth/logout',
      automationQA: () => `${API_LIST.company.root}/login/automation-qa`,

      // ONLY FOR SUPER ADMIN!!!
      superAdmin: '/company-admin-auth/login/super-admin',
    },

    legalEntities: {
      root: '/company-legal-entity',
    },
  },

  countries: {
    root: '/country',
    id: (id: string) => `/country/${id}`,
  },
  countriesWithSuggestions: '/country/with-suggestions',
  cities: '/city',
  states: '/state',

  product: {
    root: '/product',
    id: (id: string) => `product/${id}`,
    filters: '/product/filters',

    icecat: '/product/icecat',
    manual: '/product/manual',

    specDetails: '/spec-detail',
    updateSaveStatus: '/admin-saved-product',
    addToCompanyCatalog: '/admin-saved-product/save-product-to-company',
  },

  category: {
    root: '/product-category',
    id: (id: string) => `/product-category/${id}`,
  },
  subcategory: {
    root: '/product-subcategory',
    id: (id: string) => `/product-subcategory/${id}`,
  },

  cart: {
    root: '/cart',
    productToCart: '/cart/product-to-cart',
    clearCart: '/cart/clear',
  },

  onboarding: {
    stages: '/onboarding/stages',
    connectedHrSystems: '/onboarding/connected-hr-systems',
  },

  integrations: {
    root: '/company-control-system',
    mdmSystems: '/company-control-system/connected-systems',
    syncMDMIntegration: '/company-control-system/sync-system',
    disableMDMIntegration: '/company-control-system/disable-integration',
  },

  notifications: {
    root: '/notification',
    hasUnread: '/notification/has-unread',
  },

  file: '/file',
  dpnFile: '/product-code-dpn/csv',

  globalSearch: '/search/global',
  globalSearchRecent: '/search',

  slaConfig: {
    root: '/sla-config',
    id: (id: string) => `/sla-config/${id}`,
  },

  order: {
    root: {
      filters: '/order/filters',
      table: '/order/orders-table-v2',
      draft: '/order/draft',
      reset: (id: string) => `/order/${id}/reset`,
      poNumber: (id: string) => `/order/${id}/po-number`,
      actualEta: (id: string) => `/order/${id}/actual-eta`,
      regeneratePQ: (id: string) => `/order/${id}/regenerate-pq`,
      comment: {
        root: '/order-comment',
        id: (id: string) => `/order-comment/${id}`,
      },
      openedDispute: (id: string) => `/order/${id}/opened-dispute-from`,
      lastComment: (id: string) => `/order/${id}/last-comment`,
      trackingInfo: (id: string) => `/order/${id}/tracking-info`,
      atRisk: `/order/at-risk`,
      VRFContract: (id: string) => `/order/${id}/assign-vrf-contact`,
      assets: (id: string) => `/order/${id}/order-assets`,
    },

    purchase: {
      root: '/order/purchase',
      updateCountry: '/order/purchase/country',
      slug: {
        id: (id: string) => `order/purchase/${id}`,
        status: (id: string) => `order/purchase/${id}/status`,
        updateDelivery: (id: string) => `/order/purchase/${id}/delivery`,
        shippingInfo: (id: string) => `/order/purchase/${id}/shipping`,
        paymentInfo: (id: string) => `/order/purchase/${id}/billing`,
      },
      alternativeProduct: {
        root: '/alternative-order-product',
        id: (id: string) => `/alternative-order-product/${id}`,
      },
      calculateCommercialOfferMargin: '/order/purchase/margin-calculator',
      serialNumbers: (id: string) => `/order/purchase/${id}/order-product-serial-numbers`,
      addProduct: (id: string) => `/order/purchase/${id}/add-product-to-order`,
      updateProducts: (id: string) => `/order/purchase/${id}/products-to-order`,
      productsWithSerialNumbers: (id: string) => `/order/purchase/${id}/products`,
      checkSerialNumbersFillStatus: (id: string) =>
        `/order/purchase/${id}/check-serial-numbers-fill-status`,
    },

    delivery: {
      root: '/order/delivery',
      slug: {
        id: (id: string) => `order/delivery/${id}`,
        assets: (id: string) => `order/delivery/${id}/assets`,
        status: (id: string) => `order/delivery/${id}/status`,

        pickupInfo: (id: string) => `order/delivery/${id}/pick-up-info`,
        shippingInfo: (id: string) => `order/delivery/${id}/shipping-info`,
        deliveryInfo: (id: string) => `order/delivery/${id}/delivery-info`,
        invoiceInfo: (id: string) => `order/delivery/${id}/invoice-info`,
        boxConfigInfo: (id: string) => `order/delivery/${id}/box-config-info`,
        paymentInfo: (id: string) => `order/delivery/${id}/payment-info`,
        attachAssets: (id: string) => `order/delivery/${id}/attach-assets`,
        packingInfo: (id: string) => `order/delivery/${id}/packing-info`,

        cancel: (id: string) => `order/delivery/${id}/status/cancel`,
        confirm: (id: string) => `order/delivery/${id}/status/confirmed`,
        resend: (id: string) => `order/delivery/${id}/status/resend`,
        dispute: (id: string) => `order/delivery/${id}/status/in-dispute`,

        commercialOffer: (id: string) => `/order/delivery/${id}/commercial-offer`,
        assetCondition: (id: string, assetId: string) =>
          `/order/delivery/${id}/change-asset-condition/${assetId}`,
      },
    },
  },

  commercialOffer: {
    root: '/commercial-offer',
    id: (id: string) => `${API_LIST.commercialOffer.root}/${id}`,
    draft: () => `${API_LIST.commercialOffer.root}/draft`,
    revenue: () => `${API_LIST.commercialOffer.root}/revenue`,
    changePackage: (orderId: string) => `${API_LIST.commercialOffer.id(orderId)}/package-change`,
    changeValidPeriod: (orderId: string) => `${API_LIST.commercialOffer.id(orderId)}/valid-until`,
    commercialOffersPreviews: (orderId: string) =>
      `${API_LIST.commercialOffer.root}/order-commercial-offers/${orderId}`,
  },

  commercialOfferProduct: {
    root: '/commercial-offer-product',
    id: (id: string) => `${API_LIST.commercialOfferProduct.root}/${id}`,

    offer: {
      root: () => `${API_LIST.commercialOfferProduct.root}/commercial-offer`,
      id: (offerId: string) => `${API_LIST.commercialOfferProduct.offer.root()}/${offerId}`,
    },
  },

  commercialOfferPackage: {
    root: '/commercial-offer-package',
    id: (id: string) => `${API_LIST.commercialOfferPackage.root}/${id}`,

    offer: {
      root: () => `${API_LIST.commercialOfferPackage.root}/commercial-offer`,
      id: (offerId: string) => `${API_LIST.commercialOfferPackage.offer.root()}/${offerId}`,
    },
  },

  vendor: {
    root: '/vendor',
    id: (id: string) => `vendor/${id}`,
    byRegionId: (regionId: string) => `vendor/region/${regionId}`,

    vrf: {
      root: '/vendor-vrf',
      id: (id: string) => `/vendor-vrf/${id}`,
      sendForm: '/vendor-vrf/send-form',
    },
  },

  vrf: {
    root: '/vrf',
    filters: '/vrf/filters',
    id: (id: string) => `/vrf/${id}`,
    compareProductsByIds: (vrfFormId: string, vrfFormProductId: string) =>
      `/vrf/${vrfFormId}/form-product/${vrfFormProductId}`,
    sendFormById: (id: string) => `/vrf/${id}/send-form`,
    changeStatus: (id: string) => `/vrf/${id}/change-status`,
    setWinner: (vendorId: string) => `/vrf/${vendorId}/winner`,
    billingEntity: (id: string) => `/vrf/${id}/billing-entity`,

    VRFQNotes: {
      root: (orderId: string) => `/order/${orderId}/vrf-notes`,
      id: (orderId: string, noteId: string) => `/order/${orderId}/vrf-notes/${noteId}`,
    },
  },

  payment: {
    information: '/payment-information',
  },

  currency: {
    root: '/currency',
  },

  companyAdmin: {
    root: '/company-admin',
    id: (id: string) => `/company-admin/${id}`,
    salesAdmins: '/company-admin/get-sales-admins',
    supportAdmins: '/company-admin/get-support-admins',
    superAdmins: '/company-admin/super-admins',
  },
  brand: {
    root: '/brand',
    id: (id: string) => `/brand/${id}`,
  },

  dictionaries: {
    measurements: '/order/delivery/measurement-systems',
    volumetricWeight: '/order/delivery/volumetric-weight',
    estimatedArrivalTime: (id: string) => `/order/delivery/${id}/estimated-arrival-time`,
    payment: {
      information: '/payment-information',
    },
    shipping: {
      methods: '/shipping-method',
    },
    batteries: '/batteries',
  },

  region: {
    root: '/region',
  },

  export: {
    root: '/export',
    order: '/export/order',
    report: {
      stocking: {
        root: '/export/report/stocking',
        movement: '/export/report/stocking/movement',
      },
      DPN: {
        root: '/export/report/dpn',
      },
    },
    revenueChartOffers: '/export/revenue',
  },

  google: {
    placesSuggestions: '/google-api/suggestions',
    placeInfo: '/google-api/place-info',
  },

  updates: {
    root: '/updates',
    id: (id: string) => `/updates/${id}`,
  },

  pricing: {
    root: '/pricing',
    plan: {
      root: () => `${API_LIST.pricing.root}/plan`,
      id: (id: string) => `${API_LIST.pricing.plan.root()}/${id}`,
      dotsPlans: () => `${API_LIST.pricing.plan.root()}/dots`,

      company: {
        root: () => `${API_LIST.pricing.plan.root()}/company`,
        id: (id: string) => `${API_LIST.pricing.plan.company.root()}/${id}`,
        paymentHistory: (planId: string, id: string) =>
          `${API_LIST.pricing.plan.id(planId)}/company/${id}/payment-history`,
        changeTrialPeriod: (id: string) => `${API_LIST.pricing.plan.company.id(id)}/change-trial`,
      },
    },

    company: {
      root: () => `${API_LIST.pricing.root}/company`,
      id: (id: string) => `${API_LIST.pricing.company.root()}/${id}`,

      plans: (id: string) => `${API_LIST.pricing.company.id(id)}/plans`,
      paymentMethods: (id: string) => `${API_LIST.pricing.company.id(id)}/payment-methods`,
      paymentHistory: (id: string) => `${API_LIST.pricing.company.id(id)}/payment-history`,
      activePlan: (id: string) => `${API_LIST.pricing.company.id(id)}/active-plan`,
      updatePlan: (id: string) => `${API_LIST.pricing.company.id(id)}/plan/update`,
      deletePendingPlan: (id: string) => `${API_LIST.pricing.company.id(id)}/delete-pending`,
    },
  },
})
