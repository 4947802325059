import * as yup from 'yup'

export const possibleBillingEntityRegion = ['EU', 'US'] as const
export type VRFBillingEntityRegionType = (typeof possibleBillingEntityRegion)[number]

export const VRFBillingEntitySchema = yup.object({
  isChosen: yup.boolean().defined(),
  region: yup.mixed<VRFBillingEntityRegionType>().oneOf(possibleBillingEntityRegion).defined(),
  companyFullLegalRegisteredName: yup.string().defined(),
  headquartersFullAddress: yup.string().defined(),
  registeredNumber: yup.string().defined(),
  taxNumber: yup.string().defined(),
  ITIN: yup.string().defined(),
})

export const VRFBillingEntitiesSchema = yup.array().of(VRFBillingEntitySchema).defined()

export type VRFBillingEntity = yup.InferType<typeof VRFBillingEntitySchema>
