import { useEffect } from 'react'
import { SUPPORT_KEY } from '@app/services/config'
import Intercom from '@intercom/messenger-js-sdk'
import type { AdminModel } from '@share/schemas'
import { asDate } from '@share/utils'

type UseSupportControllerProps = {
  user?: AdminModel
}

export const useSupportController = ({ user }: UseSupportControllerProps) => {
  useEffect(() => {
    if (!user) return

    Intercom({
      app_id: SUPPORT_KEY,
      user_id: user.id,
      name: user.fullName,
      email: user.email,
      created_at: asDate(user.createdAt).unix(),
      vertical_padding: 45,
    })
  }, [user])
}
