import { API_LIST, API_TAGS } from '@share/constants'
import { APIError, apiRequest, queryClient } from '@share/helpers'
import { packagesSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  packages: packagesSchema,
})

type GetCommercialOffersPackagesRequest = { offerId: string }
type GetCommercialOffersPackagesResponse = yup.InferType<typeof responseSchema>

const getCommercialOffersPackagesRequest = ({ offerId }: GetCommercialOffersPackagesRequest) =>
  apiRequest<GetCommercialOffersPackagesResponse>({
    method: 'GET',
    url: API_LIST.commercialOfferPackage.offer.id(offerId),
    responseSchema,
  })

export const useGetCommercialOffersPackagesRequest = (params: GetCommercialOffersPackagesRequest) =>
  useQuery<GetCommercialOffersPackagesResponse, APIError>({
    queryKey: API_TAGS.commercialOfferPackage.offer.detail(params.offerId),
    queryFn: () => getCommercialOffersPackagesRequest(params),
  })

export const useInvalidatedGetCommercialOffersPackagesRequest = (
  params: GetCommercialOffersPackagesRequest,
) => queryClient.invalidateQueries(API_TAGS.commercialOfferPackage.offer.detail(params.offerId))
