import { isDevMode, setupInterceptorsTo } from '@share/utils'
import { validateAPIData } from '@share/utils/api'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { Schema } from 'yup'

import { axiosClient } from './axios-client'

export const { get, post, put, delete: destroy, patch } = setupInterceptorsTo(axiosClient)

class ApiClient {
  private axiosInstance = setupInterceptorsTo(axiosClient)

  public apiRequest = async <Response, Request = unknown>({
    url,
    method = 'get',
    data = null,
    params = null,
    headers = {},
    requestSchema,
    responseSchema,
  }: {
    url: string
    method?: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
    headers?: AxiosRequestConfig['headers']
    requestSchema?: Schema<Request>
    responseSchema?: Schema<Response>
  }): Promise<Response> => {
    const config: AxiosRequestConfig = {
      method,
      url,
      headers,
      ...(data && { data }),
      ...(params && { params }),
    }

    const response = await this.axiosInstance.request<Response>(config)

    if (isDevMode() && requestSchema && data) {
      validateAPIData<Request>(data, requestSchema).catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(`API request validation error --at URL:${url}`, error)
      })
    }

    if (isDevMode() && responseSchema) {
      validateAPIData<Response>(response.data, responseSchema).catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(`API response validation error --at URL:${url}`, error)
      })
    }

    return response.data
  }
}

export const { apiRequest } = new ApiClient()

export type APIError = AxiosError
