import * as yup from 'yup'

export const paginationResponseSchema = <T>(result: yup.Schema<T>) =>
  yup
    .object({
      result: yup.array().of<T>(result).defined(),
      totalCount: yup.number().defined(),
    })
    .defined()

export const getResponseWithResultSchema = <T>(result: yup.Schema<T>) =>
  yup
    .object({
      result: yup.array().of<T>(result).defined(),
    })
    .defined()

export const successResponseSchema = yup.object({
  success: yup.boolean().required().defined(),
})

export const paginationSchema = yup
  .object({
    page: yup.number().defined(),
    limit: yup.number().defined(),
    totalCount: yup.number().defined(),
  })
  .defined()
