import * as yup from 'yup'

const possiblePaymentInformationTypes = ['WIRE_TRANSFER', 'CREDITS', 'CREDIT_CARD'] as const
export type PaymentInformationType = (typeof possiblePaymentInformationTypes)[number]

export const paymentInformationModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  type: yup.mixed<PaymentInformationType>().oneOf(possiblePaymentInformationTypes).defined(),
})

export type PaymentInformationModel = yup.InferType<typeof paymentInformationModelSchema>

const possibleCanBePaidWithCreditsReasonTypes = [
  'INTERNATIONAL_DELIVERY',
  'NOT_ENOUGH_CREDITS',
  'INCOMPATIBLE_ORDER_TYPE',
] as const
export type CanBePaidWithCreditsReasonType =
  (typeof possibleCanBePaidWithCreditsReasonTypes)[number]

export const canBePaidWithCreditsSchema = yup.object({
  value: yup.boolean().defined(),
  reason: yup
    .mixed<CanBePaidWithCreditsReasonType>()
    .oneOf(possibleCanBePaidWithCreditsReasonTypes)
    .when('value', {
      is: true,
      then: (schema) => schema.defined(),
      otherwise: (schema) => schema.defined().nullable(),
    }),
})

export type CanBePaidWithCreditsModel = yup.InferType<typeof canBePaidWithCreditsSchema>
