import * as yup from 'yup'

import { productModelSchema } from '../product'
import { purchaseOrderVendorProductsDetailsModelSchema } from '../purchase/purchase-order-vendor-product-details.schemas'

import { orderAlternativeProductsModelSchema } from './order-alternative-product.schemas'
import { orderProductAdditionalServiceSchema } from './order-product-additional-service.schemas'

export const orderProductModelSchema = yup.object({
  additionalServices: yup.array(orderProductAdditionalServiceSchema).defined(),
  alternativeOrderProducts: orderAlternativeProductsModelSchema.defined(),
  commercialOfferProducts: yup.array().of(yup.string()).defined(),
  condition: yup.string().defined().nullable(),
  createdAt: yup.string().defined(),
  deletedAt: yup.string().defined().nullable(),
  id: yup.string().defined(),
  keyboardLanguage: yup.string().defined().nullable(),
  kitId: yup.string().defined().nullable(),
  notes: yup.string().defined().nullable(),
  orderId: yup.string().defined(),
  packageIndex: yup.number().defined().nullable(),
  powerSupplyType: yup.string().defined().nullable(),
  product: productModelSchema.defined(),
  productCodeDpn: yup.string().defined().nullable(),
  productCodeDpnId: yup.string().defined().nullable(),
  productId: yup.string().defined(),
  quantity: yup.number().defined(),
  serialNumbers: yup.array().of(yup.string()).defined(),
  serialNumbersWithPackageIds: yup
    .array()
    .of(
      yup.object({
        id: yup.string().defined(),
        serialNumber: yup.string().defined(),
        packageId: yup.string().defined(),
      }),
    )
    .defined(),
  totalPrice: yup.number().defined(),
  updatedAt: yup.string().defined(),
  vendorProductsDetails: purchaseOrderVendorProductsDetailsModelSchema.defined(),
  vendorProductDetail: yup.object({
    additionalServices: yup.array(orderProductAdditionalServiceSchema).defined(),
    alternativeOrderProducts: orderAlternativeProductsModelSchema.defined(),
    condition: yup.string().defined().nullable(),
    id: yup.string().defined(),
    packageIndex: yup.number().defined().nullable(),
    pricePerUnitWithoutMargin: yup.number().defined(),
    quantity: yup.number().defined(),
    vendor: yup
      .object({
        appleCareService: yup.boolean().defined(),
        autoPilotService: yup.boolean().defined(),
        companyName: yup.string().defined(),
        deletedAt: yup.string().defined().nullable(),
        depService: yup.boolean().defined(),
        email: yup.string().defined(),
        firstName: yup.string().defined(),
        id: yup.string().defined(),
        insuranceService: yup.boolean().defined(),
        lastName: yup.string().defined(),
        mainProducts: yup.string().defined(),
        notes: yup.string().defined().nullable(),
        phone: yup.string().defined(),
        primaryEntityCountryId: yup.string().defined(),
        vendorDepId: yup.string().defined(),
      })
      .defined(),
  }),
})

export const orderProductsModelSchema = yup.array(orderProductModelSchema).defined()

export type OrderProductModel = yup.InferType<typeof orderProductModelSchema>
export type OrderProductsModel = yup.InferType<typeof orderProductsModelSchema>
