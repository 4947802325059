import * as yup from 'yup'

const possiblePaymentMethodTypes = [
  'mastercard',
  'visa',
  'amex',
  'diners',
  'discover',
  'jcb',
  'unionpay',
  'Eftpos Australia',
  'Girocard',
] as const
export type PaymentMethodType = (typeof possiblePaymentMethodTypes)[number]

export const paymentMethodModelSchema = yup.object({
  id: yup.string().defined(),
  last4digits: yup.string().defined(),
  brand: yup.mixed<PaymentMethodType>().oneOf(possiblePaymentMethodTypes).defined(),
  expirationDate: yup.string().defined(),
  isDefault: yup.boolean().defined(),
  stripePaymentMethodId: yup.string().defined().nullable(),
})

export type PaymentMethodModel = yup.InferType<typeof paymentMethodModelSchema>

export const paymentMethodsModelResponseSchema = yup.object({
  result: yup.array().of(paymentMethodModelSchema).defined(),
})

export type GetPaymentMethodsResponse = yup.InferType<typeof paymentMethodsModelResponseSchema>
