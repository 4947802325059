import { generatePath } from 'react-router-dom'
import { OrderType } from '@share/schemas'

export const ROUTES = {
  root: '/',
  login: {
    root: '/login',
    automationQALogin: () => `${ROUTES.login.root}/automation-qa`,
  },

  ORDERS: {
    root: '/orders',
    purchase: {
      root: () => `${ROUTES.ORDERS.root}/purchase` as const,
      detail: () => `${ROUTES.ORDERS.purchase.root()}/:id` as const,

      editDelivery: 'edit/delivery',
      editShipping: 'edit/shipping',
      editPayment: 'edit/payment',

      createCommercialOffer: '/orders/purchase/:id/commercial-offer/create',
      editCommercialOffer: '/orders/purchase/:id/commercial-offer/edit',
      offerAlternative:
        '/orders/purchase/alternative/:orderProductId/offer/:commercialOfferProductId',
      offerAlternativeManually:
        '/orders/purchase/alternative/:orderProductId/offer-manually/:commercialOfferProductId',

      mutateVendorProductDetails: '/orders/purchase/:orderId/vendor-product-details/:id/mutate',

      commercialOffer: {
        root: () => `${ROUTES.ORDERS.purchase.detail()}/commercial-offer` as const,
        detail: () => `${ROUTES.ORDERS.purchase.commercialOffer.root()}/:offerId` as const,
        splitPackages: () =>
          `${ROUTES.ORDERS.purchase.commercialOffer.detail()}/split-packages` as const,
      },
    },
    delivery: {
      editPickup: 'edit/pickup',
      editDelivery: 'edit/delivery',
      editShipping: 'edit/shipping',
      editInvoice: 'edit/invoice',
      editPayment: 'edit/payment',
      editBoxes: 'edit/boxes',
      editAssets: 'edit/assets',
      editPacking: 'edit/packing',
      createCommercialOffer: 'create/commercial-offer',
      editCommercialOffer: 'edit/commercial-offer',
    },
  },

  slaConfig: {
    risk: '/risk-config',
    riskChart: '/risk-chart',
  },

  vendors: '/vendors',
  vendorCreate: '/vendors/create',
  vendorEdit: '/vendors/:id/edit',

  companies: {
    root: '/companies',
    slug: {
      root: () => `${ROUTES.companies.root}/:id` as const,
      overview: () => `${ROUTES.companies.slug.root()}/overview` as const,
      orders: () => `${ROUTES.companies.slug.root()}/orders` as const,
      usage: () => `${ROUTES.companies.slug.root()}/usage` as const,
      subscription: () => `${ROUTES.companies.slug.root()}/subscription` as const,
    },
  },
  companiesAdmins: '/companies-admins',
  catalog: '/catalog',
  catalogProductCreateManually: '/catalog/create-product-manually',
  catalogProductEditManually: '/catalog/product/:id/edit',

  categories: {
    root: '/categories',
  },
  subcategories: {
    root: '/subcategories',
  },
  brands: {
    root: '/brands',
  },
  updates: {
    root: '/updates',
  },
  reports: {
    root: '/reports',
  },

  RFQs: {
    root: '/rfqs',
    view: '/rfqs/:id',
  },
  countries: {
    root: '/countries',
    edit: '/countries/:id/edit',
  },

  analytics: {
    root: '/analytics',
  },

  pricing: {
    root: '/pricing',
  },
} as const

export const NAVIGATION_ROUTES = {
  orders: {
    root: ROUTES.ORDERS.root,
    toOrderById: (id: string, type: OrderType) =>
      `${NAVIGATION_ROUTES.orders.root}/${type.toLowerCase()}/${id}`,

    purchase: {
      toOrderById: (id: string) => `${ROUTES.ORDERS.root}/purchase/${id}`,
      toAlternativeProductFlow: (orderProductId: string, commercialOfferProductId: string) =>
        `${ROUTES.ORDERS.root}/purchase/alternative/${orderProductId}/offer/${commercialOfferProductId}`,
      toManuallyAlternativeProductFlow: (
        orderProductId: string,
        commercialOfferProductId: string,
      ) =>
        `${ROUTES.ORDERS.root}/purchase/alternative/${orderProductId}/offer-manually/${commercialOfferProductId}`,
      toCommercialOffer: (orderId: string, action: string) =>
        `${ROUTES.ORDERS.root}/purchase/${orderId}/commercial-offer/${action}`,
      toCommercialOfferCreate: (orderId: string) =>
        `${ROUTES.ORDERS.root}/purchase/${orderId}/commercial-offer/create`,
      toCommercialOfferEdit: (orderId: string) =>
        `${ROUTES.ORDERS.root}/purchase/${orderId}/commercial-offer/edit`,
      toVendorProductDetailsMutate: (orderId: string, id: string) =>
        `${ROUTES.ORDERS.root}/purchase/${orderId}/vendor-product-details/${id}/mutate`,
      toSplitCommercialOfferPackages: (orderId: string, offerId: string) =>
        generatePath(ROUTES.ORDERS.purchase.commercialOffer.splitPackages(), {
          id: orderId,
          offerId,
        }),
    },
    delivery: {
      toOrderById: (id: string) => `${ROUTES.ORDERS.root}/delivery/${id}`,
      toCommercialOfferCreate: (orderId: string) =>
        `${ROUTES.ORDERS.root}/delivery/${orderId}/create/commercial-offer`,
      toCommercialOfferEdit: (orderId: string) =>
        `${ROUTES.ORDERS.root}/delivery/${orderId}/edit/commercial-offer`,
    },
  },

  RFQs: {
    root: '/rfqs',
    slug: (id: string) => `/rfqs/${id}`,
  },

  countries: {
    root: '/countries',
    slug: (id: string) => `/countries/${id}`,
    edit: (id: string) => `/countries/${id}/edit`,
  },

  companies: {
    root: ROUTES.companies.root,
    slug: {
      root: (id: string) => generatePath(ROUTES.companies.slug.root(), { id }),
      overview: (id: string) => generatePath(ROUTES.companies.slug.overview(), { id }),
      orders: (id: string) => generatePath(ROUTES.companies.slug.orders(), { id }),
      usage: (id: string) => generatePath(ROUTES.companies.slug.usage(), { id }),
      subscription: (id: string) => generatePath(ROUTES.companies.slug.subscription(), { id }),
    },
  },
} as const
