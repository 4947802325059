import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CompanyIcon from '@assets/icons/company.svg'
import {
  Breadcrumbs,
  EntityDashboardDetails,
  Nullable,
  OrderStatusTag,
  Stack,
  Typography,
} from '@dots/uikit'
import { ContactIcon, SupportLargeIcon } from '@dots/uikit/icons'
import DateIcon from '@dots/uikit/icons/calendar.svg'
import EmployeeIcon from '@dots/uikit/icons/employee.svg'
import PriceIcon from '@dots/uikit/icons/price.svg'
import ShippingIcon from '@dots/uikit/icons/shipping.svg'
import VendorsIcon from '@dots/uikit/icons/vendor.svg'
import VendorDepIcon from '@dots/uikit/icons/vendor-dep.svg'
import { DashBox, Stepline, StepLineItemProps } from '@share/components/ui'
import { DeliveryOrderStatus, OrderModel, OrderStatus, OrderSubtype } from '@share/schemas'
import { currency, dateToFormat, formatDate, isDef } from '@share/utils'

import { getFormattedActualEtaDate } from '../../../common/libs'
import { OrderEditableName } from '../../../common/ui/atoms'
import { getIsOrderPending, getOrderSubtypeLabel, getOrderTrackingNumber } from '../../../lib'

import s from './dashboard.module.scss'

interface OrderDashboardProps {
  name: OrderModel['name']
  customOrderName: OrderModel['customOrderName']
  subtype: OrderSubtype
  price: OrderModel['totalPrice']
  status: OrderStatus | DeliveryOrderStatus
  actualEta: OrderModel['delivery']['actualEta']
  author: OrderModel['admin']['fullName']
  from: OrderModel['createdAt']
  trackingNumber: OrderModel['delivery']['trackingNumber']
  statusLog: OrderModel['statusLog']
  companyName: OrderModel['companyName']
  currencyCode: string
  vendorName?: string
  admins: OrderModel['admins']
  vendorDepId?: Nullable<string>
  onEditName?: () => void
}

export const OrderDashboard: React.FC<OrderDashboardProps> = memo(
  ({
    name,
    customOrderName,
    author,
    status,
    actualEta,
    price,
    trackingNumber,
    subtype,
    from,
    statusLog,
    currencyCode,
    companyName,
    vendorName,
    vendorDepId,
    admins,
    onEditName,
  }) => {
    const { t } = useTranslation()

    const isOrderPending = getIsOrderPending(status)
    const formattedActualEta = getFormattedActualEtaDate(actualEta)

    const items = useMemo(
      () =>
        [
          {
            id: t('orders.detail.sidebar.orderOverview.author'),
            label: t('orders.detail.sidebar.orderOverview.author'),
            value: author,
            icon: EmployeeIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.eta'),
            label: t('orders.detail.sidebar.orderOverview.eta'),
            value: isDef(formattedActualEta) && !isOrderPending ? formattedActualEta : 'Pending',
            icon: DateIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.price'),
            label: t('orders.detail.sidebar.orderOverview.price'),
            value:
              isDef(price) && !isOrderPending
                ? currency(price as number, { currency: currencyCode })
                : 'Pending',
            icon: PriceIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.tracking'),
            label: t('orders.detail.sidebar.orderOverview.tracking'),
            value: getOrderTrackingNumber(trackingNumber, status),
            icon: ShippingIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.procurement'),
            label: t('orders.detail.sidebar.orderOverview.procurement'),
            value: admins.procurement?.email,
            icon: ContactIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.customer-support'),
            label: t('orders.detail.sidebar.orderOverview.customer-support'),
            value: admins.customerSupport?.email,
            icon: SupportLargeIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.sales'),
            label: t('orders.detail.sidebar.orderOverview.sales'),
            value: admins.sales?.email,
            icon: EmployeeIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.company'),
            label: t('orders.detail.sidebar.orderOverview.company'),
            value: companyName,
            icon: CompanyIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.vendor'),
            label: t('orders.detail.sidebar.orderOverview.vendor'),
            value: vendorName,
            icon: VendorsIcon,
          },
          {
            id: t('orders.detail.sidebar.orderOverview.vendor-dep-id'),
            label: t('orders.detail.sidebar.orderOverview.vendor-dep-id'),
            value: vendorDepId,
            icon: VendorDepIcon,
          },
        ].filter((item) => item.value),
      [
        t,
        author,
        formattedActualEta,
        isOrderPending,
        price,
        currencyCode,
        trackingNumber,
        status,
        admins,
        companyName,
        vendorName,
        vendorDepId,
      ],
    )

    const statusLogItems: StepLineItemProps[] = statusLog
      .filter((item) => Boolean(item.status))
      .map((item) => ({
        id: item.id,
        label: item.status,
        changedBy: item.changedBy,
        caption: item.updatedAt ? formatDate(item.updatedAt, 'HH:mm DD.MM') : '',
        status: item.stage,
      }))

    return (
      <DashBox gap={16}>
        <div>
          <OrderStatusTag status={status} />
        </div>
        <Stack gap={8} align='start'>
          {customOrderName && <Typography variant='b3'>{name}</Typography>}
          <OrderEditableName isEditable name={customOrderName ?? name} onEdit={onEditName} />
          <Breadcrumbs
            list={[
              t('orders.table.body.name-subtitle', { type: getOrderSubtypeLabel(subtype) }),
              `from ${dateToFormat(from)}`,
            ]}
          />
        </Stack>

        <EntityDashboardDetails items={items} variant='boundary' className={s.details} />

        <Stepline list={statusLogItems} />
      </DashBox>
    )
  },
)

OrderDashboard.displayName = 'OrderDashboard'
