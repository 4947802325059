import { API_LIST, API_TAGS } from '@share/constants'
import { APIError, apiRequest } from '@share/helpers'
import { commercialOfferProductsSchema } from '@share/schemas/commercial-offer'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  products: commercialOfferProductsSchema.defined(),
})

type GetCommercialOffersProductsRequest = { offerId: string }
type GetCommercialOffersProductsResponse = yup.InferType<typeof responseSchema>

const getCommercialOffersProductsRequest = ({ offerId }: GetCommercialOffersProductsRequest) =>
  apiRequest<GetCommercialOffersProductsResponse>({
    method: 'GET',
    url: API_LIST.commercialOfferProduct.offer.id(offerId),
    responseSchema,
  })

export const useGetCommercialOffersProductsRequest = (params: GetCommercialOffersProductsRequest) =>
  useQuery<GetCommercialOffersProductsResponse, APIError>({
    queryKey: API_TAGS.commercialOfferProduct.offer.detail(params.offerId),
    queryFn: () => getCommercialOffersProductsRequest(params),
    placeholderData: { products: [] },
  })
