import * as yup from 'yup'

export const possiblePackageStatus = [
  'PENDING_OFFER',
  'PREPARING',
  'IN_TRANSIT',
  'DELIVERED',
] as const

export type PackageStatusTypes = (typeof possiblePackageStatus)[number]

export const packageStatusSchema = yup.mixed<PackageStatusTypes>().oneOf(possiblePackageStatus)

export const packageSchema = yup.object({
  actualDeliveryDate: yup.string().defined().nullable(),
  assetsCreated: yup.boolean().defined(),
  commercialOfferId: yup.string().defined(),
  createdAt: yup.string().defined(),
  deletedAt: yup.string().defined().nullable(),
  etaEndDate: yup.string().defined().nullable(),
  etaStartDate: yup.string().defined().nullable(),
  id: yup.string().defined(),
  packageIndex: yup.number().defined(),
  proofOfPackageDeliveryAttachments: yup.array().of(yup.string()).defined(),
  status: packageStatusSchema.defined(),
  trackingLink: yup.string().defined().nullable(),
  trackingNumber: yup.string().defined().nullable(),
  updatedAt: yup.string().defined(),
  vendor: yup
    .object({
      appleCareService: yup.boolean().defined(),
      autoPilotService: yup.boolean().defined(),
      companyName: yup.string().defined(),
      deletedAt: yup.string().nullable().defined(),
      depService: yup.boolean().defined(),
      email: yup.string().defined(),
      firstName: yup.string().defined(),
      id: yup.string().defined(),
      insuranceService: yup.boolean().defined(),
      lastName: yup.string().defined(),
      mainProducts: yup.string().defined(),
      notes: yup.string().defined(),
      phone: yup.string().defined(),
      primaryEntityCountryId: yup.string().defined(),
      vendorDepId: yup.string().defined(),
    })
    .defined()
    .nullable(),
  vendorId: yup.string().defined().nullable(),
})

export const packagesSchema = yup.array().of(packageSchema).required()

export type PackageModel = yup.InferType<typeof packageSchema>
export type PackagesModel = yup.InferType<typeof packagesSchema>
