import i18n from '@assets/locales/i18n'
import type { IconProps, Nullable } from '@dots/uikit'
import {
  AmexLargeIcon,
  AssetsIcon,
  MastercardLargeIcon,
  PriceIcon,
  PuzzleIcon,
  RocketIcon,
  RoomIcon,
  ShieldCheckIcon,
  ThunderIcon,
  VisaLargeIcon,
} from '@dots/uikit/icons'
import type {
  CustomerSupportPlanSettingValueType,
  PaymentMethodType,
  PlanSettingKeyType,
  PlanSettingType,
} from '@share/schemas'

export const PAYMENT_METHOD_BRAND_ICONS: Record<PaymentMethodType, Nullable<IconProps['icon']>> = {
  mastercard: MastercardLargeIcon,
  visa: VisaLargeIcon,
  amex: AmexLargeIcon,
  diners: null,
  discover: null,
  jcb: null,
  unionpay: null,
  Girocard: null,
  'Eftpos Australia': null,
}

export const DOTS_PLANS_SETTINGS_TYPE_CONFIG: Record<
  PlanSettingType,
  { icon: IconProps['icon']; label: string }
> = {
  START: {
    icon: ThunderIcon,
    label: i18n.t('pricing.plan-settings.start.label'),
  },
  ASSET_MANAGEMENT: {
    icon: AssetsIcon,
    label: i18n.t('pricing.plan-settings.asset-management.label'),
  },
  INTEGRATION: {
    icon: PuzzleIcon,
    label: i18n.t('pricing.plan-settings.integration.label'),
  },
  EMPLOYEES_MANAGEMENT: {
    icon: RoomIcon,
    label: i18n.t('pricing.plan-settings.employees-management.label'),
  },
  GLS: {
    icon: RocketIcon,
    label: i18n.t('pricing.plan-settings.gls.label'),
  },
  FINANCE: {
    icon: PriceIcon,
    label: i18n.t('pricing.plan-settings.finance.label'),
  },
  SECURITY: {
    icon: ShieldCheckIcon,
    label: i18n.t('pricing.plan-settings.security.label'),
  },
}

export const DOTS_PLANS_SETTINGS_KEY_CONFIG: Record<PlanSettingKeyType, string> = {
  CUSTOMER_SUPPORT: i18n.t('pricing.plan-settings.start.customer-support'),

  ASSET_MANAGEMENT: i18n.t('pricing.plan-settings.asset-management.label'),

  HR_INTEGRATION: i18n.t('pricing.plan-settings.integration.hr-integration'),
  MDM_INTEGRATION: i18n.t('pricing.plan-settings.integration.mdm-integration'),
  CUSTOM_INTEGRATION: i18n.t('pricing.plan-settings.integration.custom-integration'),

  EMPLOYEE_MANAGEMENT: i18n.t('pricing.plan-settings.employees-management.label'),
  IMPORT_WITH_SPREADSHEET: i18n.t(
    'pricing.plan-settings.employees-management.import-with-spreadsheet',
  ),

  PURCHASE_ASSETS: i18n.t('pricing.plan-settings.gls.purchase-assets'),
  DELIVERY_SERVICE: i18n.t('pricing.plan-settings.gls.delivery-services'),
  ON_OFF_BOARDING: i18n.t('pricing.plan-settings.gls.on-off-boarding'),
  STOCKING_SERVICE: i18n.t('pricing.plan-settings.gls.stocking-services'),

  CREDIT_CARD_PAYMENT: i18n.t('pricing.plan-settings.finance.card-payments'),
  WIRE_TRANSFER_PAYMENT: i18n.t('pricing.plan-settings.finance.wire-transfer'),
  CREDITS: i18n.t('pricing.plan-settings.finance.credits'),

  SOC_2: i18n.t('pricing.plan-settings.security.soc-2'),
  SSO: i18n.t('pricing.plan-settings.security.sso'),
}

export const CUSTOMER_SUPPORT_PLAN_SETTING_VALUE_CONFIG: Record<
  CustomerSupportPlanSettingValueType,
  string
> = {
  HELP_CENTER: i18n.t('pricing.plan-settings.start.customer-support.help-center'),
  HUMAN_SUPPORT_24_7: i18n.t('pricing.plan-settings.start.customer-support.human-support'),
  ACCOUNT_EXECUTIVE: i18n.t('pricing.plan-settings.start.customer-support.account-executive'),
}
