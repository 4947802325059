import * as yup from 'yup'

export const possibleOrderDiscountType = ['AMOUNT', 'PERCENTAGE'] as const
export type OrderDiscountType = (typeof possibleOrderDiscountType)[number]

export const orderDiscountModelSchema = yup
  .object({
    calculated: yup.number().optional(),
    type: yup.mixed<OrderDiscountType>().oneOf(possibleOrderDiscountType).defined(),
    value: yup.number().defined(),
    reason: yup.string().defined(),
  })
  .noUnknown()

export type OrderDiscountModel = yup.InferType<typeof orderDiscountModelSchema>
