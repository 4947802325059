import { forwardRef, useCallback, useMemo } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { DateISOString } from '@share/@types'
import cn from 'classnames'

import { DatePickerInput } from './datepicker-custom-input'
import {
  convertDateISOStringToDate,
  convertDateToDateISOStringWithoutOffset,
  renderDatePickerCustomHeader,
} from './utils'

import s from './datepicker.module.scss'

export interface RangeDatePickerProps
  extends Omit<
    ReactDatePickerProps,
    'placeholderText' | 'value' | 'onChange' | 'selectsRange' | 'selectsMultiple'
  > {
  placeholder?: string
  amountYearsShown?: number
  invalid?: boolean
  value: [DateISOString | null, DateISOString | null]
  onChange: (date: [DateISOString | null, DateISOString | null]) => void
}

export const RangeDatePicker = forwardRef<HTMLInputElement, RangeDatePickerProps>(
  (
    {
      className,
      value,
      placeholder,
      disabled,
      amountYearsShown = 5,
      invalid,
      onChange,
      yearItemNumber = 12,
      showMonthYearPicker = false,
      showYearPicker = false,
      ...datePickerProps
    },
    ref,
  ) => {
    const rootClassName = cn(s.root, className)

    const [valueStartDate, valueEndDate] = value

    const [startDate, endDate] = useMemo(
      () => [convertDateISOStringToDate(valueStartDate), convertDateISOStringToDate(valueEndDate)],
      [valueStartDate, valueEndDate],
    )

    const handleOnChange = useCallback(
      (date: [Date | null, Date | null]) => {
        const [changedStartDate, changedEndDate] = date

        onChange([
          convertDateToDateISOStringWithoutOffset(changedStartDate),
          convertDateToDateISOStringWithoutOffset(changedEndDate),
        ])
      },
      [onChange],
    )

    return (
      <div className={rootClassName}>
        <ReactDatePicker
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat='dd MMM yyyy'
          onChange={handleOnChange}
          popperPlacement='bottom-end'
          wrapperClassName={s.wrapper}
          calendarClassName={s.calendar}
          showPopperArrow={false}
          placeholderText={placeholder}
          clearButtonClassName={s.clearButton}
          isClearable
          customInput={<DatePickerInput ref={ref} disabled={disabled} invalid={invalid} />}
          showYearPicker={showYearPicker}
          showMonthYearPicker={showMonthYearPicker}
          yearItemNumber={yearItemNumber}
          renderCustomHeader={(customHeaderProps) =>
            renderDatePickerCustomHeader({
              showMonthYearPicker,
              showYearPicker,
              customHeaderProps,
              amountYearsShown,
              yearItemNumber,
            })
          }
          {...datePickerProps}
        />
      </div>
    )
  },
)

RangeDatePicker.displayName = 'RangeDatePicker'
