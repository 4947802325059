import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { adminSchema } from '@share/schemas'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({ user: adminSchema })

type AutomationQALoginRequest = { email: string }
type AutomationQALoginResponse = yup.InferType<typeof responseSchema>

const automationQALoginRequest = async (data: AutomationQALoginRequest) =>
  apiRequest<AutomationQALoginResponse>({
    data,
    url: API_LIST.company.auth.automationQA(),
    method: 'POST',
    responseSchema,
  })

export const useAutomationQALoginRequest = () => useMutation(automationQALoginRequest)
