import * as yup from 'yup'

export const possiblePlanTypes = ['FREE', 'PRO', 'ENTERPRISE'] as const
export type PlanType = (typeof possiblePlanTypes)[number]

export const possiblePlanDurationTypes = ['MONTHLY', 'YEARLY'] as const
export type PlanDurationType = (typeof possiblePlanDurationTypes)[number]

export const possiblePlanSettingTypes = [
  'START',
  'ASSET_MANAGEMENT',
  'INTEGRATION',
  'EMPLOYEES_MANAGEMENT',
  'GLS',
  'FINANCE',
  'SECURITY',
] as const
export type PlanSettingType = (typeof possiblePlanSettingTypes)[number]

export const possiblePlanSettingKeyTypes = [
  'CUSTOMER_SUPPORT',
  'ASSET_MANAGEMENT',
  'HR_INTEGRATION',
  'MDM_INTEGRATION',
  'CUSTOM_INTEGRATION',
  'EMPLOYEE_MANAGEMENT',
  'IMPORT_WITH_SPREADSHEET',
  'PURCHASE_ASSETS',
  'DELIVERY_SERVICE',
  'ON_OFF_BOARDING',
  'STOCKING_SERVICE',
  'CREDIT_CARD_PAYMENT',
  'WIRE_TRANSFER_PAYMENT',
  'CREDITS',
  'SOC_2',
  'SSO',
] as const
export type PlanSettingKeyType = (typeof possiblePlanSettingKeyTypes)[number]

export type PlanSettingValueType = string | number | boolean

const possiblePlanSettingDataTypes = ['STRING', 'NUMBER', 'BOOLEAN'] as const
export type PlanSettingDataType = (typeof possiblePlanSettingDataTypes)[number]

const possibleCustomerSupportPlanSettingValueTypes = [
  'HELP_CENTER',
  'HUMAN_SUPPORT_24_7',
  'ACCOUNT_EXECUTIVE',
] as const
export type CustomerSupportPlanSettingValueType =
  (typeof possibleCustomerSupportPlanSettingValueTypes)[number]

export const planSettingModelSchema = yup.object({
  id: yup.string().defined(),
  type: yup.mixed<PlanSettingType>().oneOf(possiblePlanSettingTypes).defined(),
  key: yup.mixed<PlanSettingKeyType>().oneOf(possiblePlanSettingKeyTypes).defined(),
  value: yup.mixed<PlanSettingValueType>().defined(),
  dataType: yup.mixed<PlanSettingDataType>().oneOf(possiblePlanSettingDataTypes).defined(),
})

export const dotsPlanModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  type: yup.mixed<PlanType>().oneOf(possiblePlanTypes).defined(),
  description: yup.string().defined(),
  price: yup.object({
    monthly: yup.number().defined(),
    yearly: yup
      .object({
        perMonth: yup.number().defined(),
        total: yup.number().defined(),
      })
      .nullable(),
  }),
  planSettings: yup.array().of(planSettingModelSchema.defined()).defined(),
})

export type DotsPlanModel = yup.InferType<typeof dotsPlanModelSchema>

export const dotsPlansModelResponseSchema = yup.object({
  result: yup.array().of(dotsPlanModelSchema).defined(),
})

export type GetDotsPlansResponse = yup.InferType<typeof dotsPlansModelResponseSchema>
