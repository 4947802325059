import * as yup from 'yup'

export const commercialOfferProductSchema = yup.object({
  additionalInfo: yup
    .array(
      yup.object({
        isSerialNumberRequired: yup.boolean().defined(),
        packageId: yup.string().defined(),
        serialNumber: yup.string().defined().nullable(),
        serialNumberId: yup.string().defined().nullable(),
        vendorId: yup.string().defined().nullable(),
      }),
    )
    .defined(),
  productId: yup.string().defined(),
})

export const commercialOfferProductsSchema = yup.array(commercialOfferProductSchema).defined()

export type CommercialOfferProduct = yup.InferType<typeof commercialOfferProductSchema>
export type CommercialOfferProducts = yup.InferType<typeof commercialOfferProductsSchema>
