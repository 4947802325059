import * as yup from 'yup'

import {
  dotsPlanModelSchema,
  PlanDurationType,
  planSettingModelSchema,
  possiblePlanDurationTypes,
} from '../pricing'

import { paymentMethodModelSchema } from './company-payment-method.schemas'

export const possiblePaymentSystemTypes = ['STRIPE', 'WIRED_TRANSFER'] as const
export type PaymentSystemType = (typeof possiblePaymentSystemTypes)[number]

const possiblePlanStatusTypes = ['ACTIVE', 'PENDING'] as const
export type PlanStatusType = (typeof possiblePlanStatusTypes)[number]

export const companyPlanModelSchema = dotsPlanModelSchema
  .pick(['id', 'name', 'type', 'description', 'planSettings'])
  .concat(
    yup.object({
      shortId: yup.string().defined(),
      duration: yup.mixed<PlanDurationType>().oneOf(possiblePlanDurationTypes).defined(),
      price: yup.number().defined(),
      downgradeReason: yup.string().defined().nullable(),
      status: yup.mixed<PlanStatusType>().oneOf(possiblePlanStatusTypes).defined(),
      stripeSubscriptionId: yup.string().defined(),
      isTrialUsed: yup.boolean().defined(),
      trial: yup
        .object({
          startDate: yup.string().defined(),
          endDate: yup.string().defined(),
        })
        .defined()
        .nullable(),
      subscription: yup
        .object({
          startDate: yup.string().defined(),
          endDate: yup.string().defined(),
        })
        .defined()
        .nullable(),
      planSettings: yup.array().of(planSettingModelSchema.defined()).defined(),
      createdAt: yup.string().defined(),
      updatedAt: yup.string().defined(),
      paymentSystem: yup.mixed<PaymentSystemType>().oneOf(possiblePaymentSystemTypes).defined(),
      paymentMethod: paymentMethodModelSchema.omit(['stripePaymentMethodId']).defined().nullable(),
      lastUpdatedBy: yup
        .object({
          id: yup.string().defined(),
          email: yup.string().defined(),
          fullName: yup.string().defined(),
        })
        .defined()
        .nullable(),
    }),
  )

export type CompanyPlanModel = yup.InferType<typeof companyPlanModelSchema>

export const companyPlansModelResponseSchema = yup
  .object({
    result: yup.array().of(companyPlanModelSchema).defined(),
  })
  .defined()

export type GetCompanyPlansResponse = yup.InferType<typeof companyPlansModelResponseSchema>
