import type { NotDefinable } from '@dots/uikit'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.tz.guess()

export const dateFunc = dayjs

export const asDate = (date: dayjs.ConfigType) => dateFunc(date)

export const dateToFormat = (date: dayjs.ConfigType) => asDate(date).format('DD MMM YYYY')

export const toISO = (date: dayjs.ConfigType) => asDate(date).format('YYYY-MM-DDTHH:mm:ss')

export const formatDate = (date: dayjs.ConfigType, format = 'DD MMM YYYY') =>
  asDate(date).format(format)

export const getFormattedTimeRange = ({
  start,
  end,
  format = 'DD MMM, HH:mm',
}: {
  start: NotDefinable<string>
  end: NotDefinable<string>
  format?: string
}) => {
  if (!start) return ''
  const formattedStart = formatDate(start, format)

  if (end) {
    const formattedEnd = formatDate(end, format)
    return `${formattedStart} - ${formattedEnd}`
  }

  return formattedStart
}

export const getTomorrowDate = () => dayjs().add(1, 'day').toDate()

export const isoToDate = (date: string) => new Date(date)

export const getDaysAmount = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}): number => {
  const diff = asDate(endDate).diff(asDate(startDate), 'day')
  if (diff < 0) return 0
  return diff
}

export const DATE_FORMAT_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss.SSS'
