export const API_TAGS = {
  auth: 'auth',
  assets: 'assets',
  assetsStatusesCount: 'assetsStatusesCount',
  countOfEmptySerialNumberAssets: 'countOfEmptySerialNumberAssets',
  assetsBrands: 'assetsBrands',
  availableStorages: 'availableStorages',
  availableLocations: 'availableLocations',
  admins: 'admins',
  companyStaff: 'companyStaff',
  companyActivities: 'companyActivities',
  companyScales: 'companyScales',
  companyStaffFilters: 'companyStaffFilters',
  companyStaffRole: 'companyStaffRole',
  companyStaffTeam: 'companyStaffTeam',
  companyOffice: 'companyOffice',
  companyOfficeLocations: 'companyOfficeLocations',
  companyWarehouse: 'companyWarehouse',
  companyOfficeSpace: 'companyOfficeSpace',
  companyOfficeFilters: 'companyOfficeFilters',
  companyCreditsLogs: 'companyCreditsLogs',
  countries: 'countries',
  currencies: 'currencies',
  countriesWithSuggestions: 'countriesWithSuggestions',
  cities: 'cities',
  states: 'states',
  onboardingStages: 'onboardingStages',
  assetsFilters: 'assetsFilters',
  product: 'product',
  icecatProduct: 'icecatProduct',
  productsFilters: 'productsFilters',
  productCategories: 'productCategories',
  productCategory: 'productCategory',
  productSubcategories: 'productSubcategories',
  productSubcategory: 'productSubcategory',
  productSpecDetails: 'productSpecDetails',
  cart: 'cart',
  hrIntegrations: 'integrations',
  MDMIntegrations: 'MDMintegrations',
  siteSearch: 'siteSearch',
  siteSearchRecent: 'siteSearchRecent',
  notificationsCenter: 'notificationsCenter',
  hasUnreadNotifications: 'hasUnreadNotifications',
  assetStock: 'assetStock',

  orders: {
    root: {
      table: 'orders/table',
      filters: 'orders/filters',
    },

    purchase: {
      root: 'orders/purchase',
      draft: 'orders/purchase/draft',
    },

    delivery: {
      root: 'orders/delivery',
      assetsSelection: {
        assets: 'orders/delivery/assets',
      },
      draft: 'orders/delivery/draft',
    },
  },

  payment: {
    information: 'paymentInformation',
  },
  company: {
    root: 'company',
    legalEntities: {
      root: 'companyLegalEntities',
    },
  },
  vendor: {
    root: 'vendor',
    byRegionId: (id: string) => ['vendor', id],
  },
  companyAdmin: {
    root: 'companyAdmin',
    salesAdmins: 'salesAdmins',
    supportAdmins: 'supportAdmins',
    superAdmins: 'superAdmins',
  },
  brand: {
    root: 'brand',
  },
  region: {
    root: 'region',
  },

  slaConfig: {
    root: 'sla-config',
  },

  ORDERS: {
    root: {
      table: 'orders/table',
      filters: 'orders/filters',
      dispute: 'orders/dispute',
      lastComment: 'orders/lastComment',
      commercialOffer: 'orders/commercialOffer',
      trackingInfo: 'orders/trackingInfo',
      commercialOffersPreviews: 'orders/commercialOffersPreviews',
      atRisk: 'orders/atRisk',
      commercialOfferProduct: 'orders/commercialOffer/commercialOfferProduct',
      commercialOfferRevenue: 'orders/commercialOffer/revenue',
      assets: 'order/assets',
    },

    purchase: {
      root: 'orders/purchase',
      draft: 'orders/purchase/draft',
      productsWithSerialNumbers: 'orders/purchase/products-with-serial-numbers',
      checkSerialNumbersFilledStatus: 'orders/purchase/check-serial-numbers-fill-status',
    },

    delivery: {
      root: 'orders/delivery',
      assetsSelection: {
        assets: 'orders/delivery/assets',
      },
      draft: 'orders/delivery/draft',
    },
  },

  commercialOfferProduct: {
    root: ['commercial-offer-product'],
    details: () => [...API_TAGS.commercialOfferProduct.root, 'details'],
    detail: (id: string) => [...API_TAGS.commercialOfferProduct.details(), id],

    offer: {
      root: () => [...API_TAGS.commercialOfferProduct.root, 'commercial-offer'],
      details: () => [...API_TAGS.commercialOfferProduct.offer.root(), 'details'],
      detail: (offerId: string) => [...API_TAGS.commercialOfferProduct.offer.details(), offerId],
    },
  },

  commercialOfferPackage: {
    root: ['commercial-offer-package'],
    details: () => [...API_TAGS.commercialOfferPackage.root, 'details'],
    detail: (id: string) => [...API_TAGS.commercialOfferPackage.details(), id],

    offer: {
      root: () => [...API_TAGS.commercialOfferPackage.root, 'commercial-offer'],
      details: () => [...API_TAGS.commercialOfferPackage.offer.root(), 'details'],
      detail: (offerId: string) => [...API_TAGS.commercialOfferPackage.offer.details(), offerId],
    },
  },

  dictionaries: {
    root: ['dictionaries'],
    measurements() {
      return [...this.root, 'measurements']
    },
    volumetricWeight() {
      return [...this.root, 'volumetricWeight']
    },
    shippingMethods() {
      return [...this.root, 'shippingMethods']
    },
    paymentInformation() {
      return [...this.root, 'paymentInformation']
    },
    batteries() {
      return [...this.root, 'batteries']
    },
  },

  export: {
    root: 'export',
    order: 'export/order',
    report: {
      stoking: 'export/report/stocking',
      movement: 'export/report/stocking/movement',
      DPN: 'export/report/dpn',
    },
    revenueChartOffers: 'export/revenue',
  },

  updates: {
    root: 'updates',
  },

  vrf: {
    root: 'vrf',
    vrfFormProduct: 'vrf/vrfFormProduct',
    filters: 'vrf/filters',
    VRFQNotes: 'vrf/VRFQNotes',
    billingEntity: 'vrf/billingEntity',
  },

  pricing: {
    root: ['pricing'],
    details: () => [...API_TAGS.pricing.root, 'detail'],

    plans: {
      root: () => [...API_TAGS.pricing.root, 'plan'],
      dotsPlans: () => [...API_TAGS.pricing.plans.root(), 'dotsPlans'],

      companies: {
        root: () => [...API_TAGS.pricing.plans.root(), 'company'],
      },
    },

    company: {
      root: () => [...API_TAGS.pricing.root, 'company'],
      plans: (params?: object) => [...API_TAGS.pricing.company.root(), 'plans', params],
      paymentMethods: (params?: object) => [
        ...API_TAGS.pricing.company.root(),
        'paymentMethods',
        params,
      ],
      paymentHistory: (params?: object) => [
        ...API_TAGS.pricing.company.root(),
        'paymentHistory',
        params,
      ],
      activePlan: (params?: object) => [...API_TAGS.pricing.company.root(), 'activePlan', params],
    },
  },

  companies: {
    root: ['companies'],
    rootParams: (params?: object) => [...API_TAGS.companies.root, params],

    details: () => [...API_TAGS.companies.root, 'details'],
    detail: (companyId: string) => [...API_TAGS.companies.details(), companyId],
  },

  // ONLY FOR SUPER ADMIN!!!
  superAdminCompanies: 'superAdminCompanies',
}
