import { PaginationState, SortState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { paginationResponseSchema, paymentHistorySchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = paginationResponseSchema(paymentHistorySchema)

interface GetCompanyPaymentHistoryParams extends Partial<PaginationState>, SortState {
  id: string
}
type GetPaymentHistoryResponse = yup.InferType<typeof responseSchema>

const getCompanyPaymentHistoryRequest = ({ id, ...params }: GetCompanyPaymentHistoryParams) =>
  apiRequest<GetPaymentHistoryResponse>({
    params,
    method: 'GET',
    url: API_LIST.pricing.company.paymentHistory(id),
    responseSchema,
  })

const placeholderData = { result: [], totalCount: 0 }

export const useGetCompanyPaymentHistoryRequest = (params: GetCompanyPaymentHistoryParams) =>
  useQuery({
    placeholderData,
    queryKey: API_TAGS.pricing.company.paymentHistory(params),
    queryFn: () => getCompanyPaymentHistoryRequest(params),
  })

export const invalidateCompanyPaymentHistory = () =>
  queryClient.invalidateQueries([...API_TAGS.pricing.company.root(), 'paymentHistory'])
