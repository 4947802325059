import { Bar } from '../../atoms'
import { BotBar, MidBar, TopBar } from '../../molecules'

export const Sidebar: React.FC = () => (
  <Bar>
    <TopBar />
    <MidBar />
    <BotBar />
  </Bar>
)
