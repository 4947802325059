import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import ArrowLeftIcon from '@assets/icons/arrow-left.svg'
import ArrowRightIcon from '@assets/icons/arrow-right.svg'
import { Flex } from '@dots/uikit'
import { MOUNTS } from '@share/constants'
import { asDate, range } from '@share/utils'

import { ActionIcon } from '../../../atoms'

import s from '../datepicker.module.scss'

interface DatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  amountYearsShown?: number
}

export const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  amountYearsShown = 5,
}) => {
  const years = range(1990, asDate(new Date()).get('year') + amountYearsShown)

  return (
    <Flex gap={10} className={s.header}>
      <ActionIcon
        disabled={prevMonthButtonDisabled}
        size='small'
        variant='subtle'
        icon={ArrowLeftIcon}
        onClick={decreaseMonth}
      />
      <select
        className={s.select}
        value={asDate(date).get('year')}
        onChange={({ target: { value } }) => changeYear(Number(value))}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        className={s.select}
        value={MOUNTS[asDate(date).get('month')]}
        onChange={({ target: { value } }) => changeMonth(MOUNTS.indexOf(value))}
      >
        {MOUNTS.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <ActionIcon
        disabled={nextMonthButtonDisabled}
        size='small'
        variant='subtle'
        icon={ArrowRightIcon}
        onClick={increaseMonth}
      />
    </Flex>
  )
}
