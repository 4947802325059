import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import { DateISOString } from '@share/@types'
import { asDate } from '@share/utils'

import { DatePickerHeader } from './headers/header'
import { MonthPickerHeader } from './headers/month-picker-header'
import { YearsPickerHeader } from './headers/years-picker-header'

export const convertDateISOStringToDate = (date: DateISOString | null | undefined) => {
  if (date && asDate(date).isValid()) {
    return asDate(date).startOf('day').toDate()
  }

  return null
}

export const convertDateToDateISOStringWithoutOffset = (date: Date | null) => {
  if (date) return asDate(date).startOf('day').format('YYYY-MM-DDT00:00:00.000')

  return null
}

export const renderDatePickerCustomHeader = ({
  showMonthYearPicker,
  showYearPicker,
  customHeaderProps,
  amountYearsShown,
  yearItemNumber,
}: {
  showMonthYearPicker: boolean
  showYearPicker: boolean
  customHeaderProps: ReactDatePickerCustomHeaderProps
  amountYearsShown: number
  yearItemNumber: number
}) => {
  if (showMonthYearPicker)
    return <MonthPickerHeader amountYearsShown={amountYearsShown} {...customHeaderProps} />

  if (showYearPicker) {
    return <YearsPickerHeader yearItemNumber={yearItemNumber} {...customHeaderProps} />
  }

  return <DatePickerHeader amountYearsShown={amountYearsShown} {...customHeaderProps} />
}
