import * as yup from 'yup'

import { toUndef } from '../../utils/forms'
import {
  PlanDurationType,
  planSettingModelSchema,
  PlanType,
  possiblePlanDurationTypes,
  possiblePlanTypes,
} from '../pricing'

import {
  companyPlanModelSchema,
  PaymentSystemType,
  possiblePaymentSystemTypes,
} from './company-plan.schemas'

export const companyActivePlanModelSchema = companyPlanModelSchema.pick([
  'id',
  'name',
  'description',
  'type',
  'duration',
  'trial',
  'subscription',
  'price',
  'downgradeReason',
  'planSettings',
  'createdAt',
  'updatedAt',
  'paymentSystem',
  'paymentMethod',
])

export type CompanyActivePlanModel = yup.InferType<typeof companyActivePlanModelSchema>

export const companyActivePlanResponseSchema = yup.object({
  result: companyActivePlanModelSchema.defined(),
})

export type GetCompanyActivePlanResponse = yup.InferType<typeof companyActivePlanResponseSchema>

export const updateCompanyActivePlanSchema = yup.object({
  planType: yup.mixed<PlanType>().label('Plan type').oneOf(possiblePlanTypes).defined().required(),
  planStartDate: yup.string().label('Plan start date').defined().required(),
  planDuration: yup
    .mixed<PlanDurationType>()
    .label('Plan duration')
    .oneOf(possiblePlanDurationTypes)
    .when('planType', {
      is: (planType: PlanType) => planType !== 'FREE',
      then: (schema) => schema.defined().required(),
      otherwise: (schema) => schema.notRequired().transform(toUndef),
    }),
  paymentSystem: yup
    .mixed<PaymentSystemType>()
    .label('Payment system')
    .oneOf(possiblePaymentSystemTypes)
    .when('planType', {
      is: (planType: PlanType) => planType !== 'FREE',
      then: (schema) => schema.defined().required(),
      otherwise: (schema) => schema.notRequired().transform(toUndef),
    }),
  paymentMethodId: yup
    .string()
    .label('Payment method')
    .when(['planType', 'paymentSystem'], {
      is: (planType: PlanType, paymentSystem: PaymentSystemType) =>
        planType !== 'FREE' && paymentSystem !== 'WIRED_TRANSFER',
      then: (schema) => schema.defined().required(),
      otherwise: (schema) => schema.notRequired().transform(toUndef),
    }),
  price: yup
    .number()
    .label('Price')
    .typeError('Price must be greater than 0')
    .when('planType', {
      is: (planType: PlanType) => planType === 'ENTERPRISE',
      then: (schema) => schema.defined().moreThan(0).required(),
      otherwise: (schema) => schema.notRequired().transform(toUndef),
    }),
  settings: yup
    .array()
    .label('Plan settings')
    .of(planSettingModelSchema.omit(['id']).defined())
    .defined(),
})

export type UpdateCompanyActivePlanModel = yup.InferType<typeof updateCompanyActivePlanSchema>
