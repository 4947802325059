import { API_LIST } from '@share/constants'
import { apiRequest } from '@share/helpers/http-client'
import { packageSchema, packagesSchema } from '@share/schemas'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  newPackage: packageSchema.defined(),
  packages: packagesSchema.defined(),
})

interface AddCommercialOfferPackageRequest {
  commercialOfferId: string
}

type AddCommercialOfferPackageResponse = yup.InferType<typeof responseSchema>

const addCommercialOfferPackageRequest = async (data: AddCommercialOfferPackageRequest) =>
  apiRequest<AddCommercialOfferPackageResponse>({
    data,
    method: 'POST',
    url: API_LIST.commercialOfferPackage.root,
    responseSchema,
  })

export const useAddCommercialOfferPackageRequest = () =>
  useMutation(addCommercialOfferPackageRequest)
