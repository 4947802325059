import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from '@app/providers/intercom-provider'
import { LoadingOverlay, TooltipProvider as TooltipProviderKit } from '@dots/uikit'
import { Provider as ModalsProvider } from '@ebay/nice-modal-react'
import { useOnSupportOpen } from '@features/support'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { ErrorBoundary, HintContainer } from '@share/components/layouts'
import { queryClient } from '@share/helpers'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export const withProviders = (WrappedComponent: React.FC) => {
  const ComponentWithProviders = () => {
    const onConcatSupport = useOnSupportOpen()

    return (
      <HelmetProvider>
        <IntercomProvider>
          <ErrorBoundary onContactSupport={onConcatSupport}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <Suspense fallback={<LoadingOverlay variant='naked' />}>
                  <TooltipProvider>
                    <TooltipProviderKit>
                      <ModalsProvider>
                        <WrappedComponent />
                      </ModalsProvider>
                    </TooltipProviderKit>
                  </TooltipProvider>
                </Suspense>

                <HintContainer />

                <ReactQueryDevtools position='bottom-left' />
              </QueryClientProvider>
            </BrowserRouter>
          </ErrorBoundary>
        </IntercomProvider>
      </HelmetProvider>
    )
  }

  ComponentWithProviders.displayName = 'withProviders'

  return ComponentWithProviders
}
