import { forwardRef } from 'react'
import IconCalendar from '@assets/icons/calendar.svg'
import { Input } from '@dots/uikit'

import { Icon, InputProps } from '../../atoms'

export interface DatePickerInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(
  ({ onChange, ...rest }, ref) => (
    <Input
      {...rest}
      ref={ref}
      slotAppend={<Icon icon={IconCalendar} />}
      onChange={(_value, e) => onChange?.(e)}
    />
  ),
)

DatePickerInput.displayName = 'DatePickerInput'
