import { useCallback, useState } from 'react'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import ArrowLeftIcon from '@assets/icons/arrow-left.svg'
import ArrowRightIcon from '@assets/icons/arrow-right.svg'
import { ActionIcon, Flex, Typography } from '@dots/uikit'

interface DatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  yearItemNumber: number
}

export const YearsPickerHeader: React.FC<DatePickerHeaderProps> = ({
  date,
  yearItemNumber = 12,
  decreaseYear,
  increaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const currentYear = new Date(date).getFullYear()
  const startYear = currentYear - ((currentYear - 1) % yearItemNumber)

  const [startYearState, setStartYearState] = useState(startYear)
  const yearsRange = `${startYearState} - ${startYearState + yearItemNumber - 1}`

  const onDecreaseYear = useCallback(() => {
    decreaseYear()
    setStartYearState(startYearState - yearItemNumber)
  }, [decreaseYear, startYearState, yearItemNumber])

  const onIncreaseYear = useCallback(() => {
    increaseYear()
    setStartYearState(startYearState + yearItemNumber)
  }, [increaseYear, startYearState, yearItemNumber])

  return (
    <Flex gap={10} align='center' justify='center'>
      <ActionIcon
        disabled={prevMonthButtonDisabled}
        size='small'
        variant='subtle'
        icon={ArrowLeftIcon}
        onClick={onDecreaseYear}
      />
      <Typography variant='b1'>{yearsRange}</Typography>
      <ActionIcon
        disabled={nextMonthButtonDisabled}
        size='small'
        variant='subtle'
        icon={ArrowRightIcon}
        onClick={onIncreaseYear}
      />
    </Flex>
  )
}
