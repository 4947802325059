/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import React from 'react'
import { ErrorNoConnection } from '@share/components/ui'

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
}

interface Props {
  children?: React.ReactNode
  onContactSupport: () => void
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const chunkFailedMessage = /Failed to fetch dynamically imported module/

    if (chunkFailedMessage.test(error?.message)) {
      window.location.reload()
      return
    }

    console.error('componentDidCatch Error:', error?.message)
    console.error('componentDidCatch errorInfo:', errorInfo)
    console.error('Uncaught error:', error, errorInfo)

    this.setState({
      hasError: true,
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={style}>
          <ErrorNoConnection onContactSupport={this.props.onContactSupport} />
        </div>
      )
    }

    return this.props.children
  }
}
