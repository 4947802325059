import { ApiError } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { VRFTableModelsSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  pagination: yup
    .object({
      page: yup.number().defined(),
      limit: yup.number().defined(),
      totalCount: yup.number().defined(),
    })
    .defined(),
  requestForQuotes: VRFTableModelsSchema.defined(),
})

export type GetVRFsRequest = {
  [key: string]: string | string[]
}

export type GetVRFsResponse = yup.InferType<typeof responseSchema>

const getVRFsRequest = (params?: GetVRFsRequest) =>
  apiRequest<GetVRFsResponse>({
    url: API_LIST.vrf.root,
    params,
    responseSchema,
  })

export const useGetVRFsRequest = (params?: GetVRFsRequest) =>
  useQuery<GetVRFsResponse, ApiError>({
    keepPreviousData: true,
    queryKey: [API_TAGS.vrf.root, params],
    queryFn: () => getVRFsRequest(params),
  })

export const invalidateVRFs = () => queryClient.invalidateQueries([API_TAGS.vrf.root])
