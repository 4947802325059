import { ENABLE_AUTOMATION_QA_TOOLS, SENTRY_ENV } from '@app/services/config'

export function isUndef(v: unknown) {
  return v === undefined || v === null
}

export function isDef(v: unknown) {
  return v !== undefined && v !== null
}

/**
 * Check if value is primitive.
 */
export function isPrimitive(value: unknown) {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'symbol' ||
    typeof value === 'boolean'
  )
}

export const isDevMode = () => SENTRY_ENV === 'development'

export const isEmptyObject = (obj: Record<string, unknown>) => Object.keys(obj).length === 0

export const toggleArrayItem = (array: string[], item: string) => {
  if (array.includes(item)) {
    return array.filter((i) => i !== item)
  }
  return [...array, item]
}

export const isAutomationQAToolsEnabled = () => ENABLE_AUTOMATION_QA_TOOLS === 'true'
