import { Children } from '@dots/uikit'
import { selectUser, useUserStore } from '@features/auth'
import { useSupportController } from '@features/support/model/controller'

export const IntercomProvider: React.FC<Children> = ({ children }) => {
  const adminStore = useUserStore(selectUser)
  useSupportController({ user: adminStore })

  return <>{children}</>
}
