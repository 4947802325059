import * as yup from 'yup'

import { orderTypeSchema } from '../order/order-type.schemas'

import { paymentMethodModelSchema } from './company-payment-method.schemas'
import {
  companyPlanModelSchema,
  PaymentSystemType,
  possiblePaymentSystemTypes,
} from './company-plan.schemas'

const possiblePaymentHistoryStatuses = ['SUCCESS', 'FAILED'] as const
export type PaymentHistoryStatus = (typeof possiblePaymentHistoryStatuses)[number]

export const paymentHistorySchema = yup.object({
  id: yup.string().defined(),
  amount: yup.number().defined(),
  status: yup.mixed<PaymentHistoryStatus>().oneOf(possiblePaymentHistoryStatuses).defined(),
  invoiceLink: yup.string().defined(),
  plan: companyPlanModelSchema.defined().nullable(),
  order: yup
    .object({
      id: yup.string().defined(),
      name: yup.string().defined(),
      type: orderTypeSchema.defined(),
    })
    .defined()
    .nullable(),
  paymentMethod: paymentMethodModelSchema.defined().nullable(),
  paymentType: yup.mixed<PaymentSystemType>().oneOf(possiblePaymentSystemTypes).defined(),
  createdAt: yup.string().defined(),
})

export type PaymentHistory = yup.InferType<typeof paymentHistorySchema>
