import { useTranslation } from 'react-i18next'
import RetryIcon from '@assets/icons/retry.svg'
import OfflineImage from '@assets/images/errors/offline.png'
import { Flex } from '@dots/uikit'
import { SupportLargeIcon } from '@dots/uikit/icons'
import { asHtml } from '@share/utils'

import { Button, Stack, Typography } from '../../atoms'

interface ErrorNoConnectionProps {
  onContactSupport: () => void
}

export const ErrorNoConnection: React.FC<ErrorNoConnectionProps> = ({ onContactSupport }) => {
  const { t } = useTranslation()

  const onClick = () => {
    window.location.reload()
  }

  return (
    <Stack gap={24} align='center'>
      <img width={160} height={160} src={OfflineImage} alt='error' />

      <Stack gap={8} align='center'>
        <Typography component='h2' variant='h2'>
          {t('error-boundary.no-internet.title')}
        </Typography>
        <Typography component='p' variant='b2' align='center' className='fcSecondary'>
          {asHtml(t('error-boundary.no-internet.subtitle'))}
        </Typography>
      </Stack>

      <Flex gap={20}>
        <Button type='button' onClick={onContactSupport} icon={SupportLargeIcon}>
          {t('actions.contact-support')}
        </Button>
        <Button type='button' onClick={onClick} icon={RetryIcon}>
          {t('error-boundary.no-internet.actions.reload')}
        </Button>
      </Flex>
    </Stack>
  )
}
