import { Typography } from '@dots/uikit'
import { APP_VERSION } from '@share/constants'

export const BotBar: React.FC = () => (
  <div>
    <Typography variant='b3' className='fcMuted'>
      Version {APP_VERSION}
    </Typography>
  </div>
)
